.Album {
    position: relative;
    margin: 0;
    padding: 0;
}

.Album img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: filter 0.5s;
    filter: blur(0px);
}

.Album img:hover {
    filter: blur(5px);
}

.Album-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: var(--secondary-bg-color);
    color: var(--main-bg-color);
    text-align: center;
}

.Album-overlay h4{
    margin-top: 75px;
}

.Album:hover .Album-overlay {
    opacity: 0.9;
}