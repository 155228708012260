@import url(https://fonts.googleapis.com/css?family=Roboto:300,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-bg-color: #dddddd;
  --secondary-bg-color: #222222;
  --highlight-color: khaki;
  --highlight-inverse-color: #9d91c3;
}

.App {
  background-color: #dddddd;
  background-color: var(--main-bg-color);
  color: #222222;
  color: var(--secondary-bg-color);
  font-family: 'Roboto', sans-serif;
  display: grid;
  height: 100vh;
  grid-template-rows: 50px 1fr 40px;
  grid-template-areas:
  "head head head head"
  "main main main main"
  "foot foot foot foot";
}

.App-header {
  grid-area: head;
}

.App-content {
  grid-area: main;
  overflow: scroll;
}

.App-footer {
  grid-area: foot;
  justify-self: center;
  align-self: center;
}

.signature {
  align-self: center;
  justify-self: end;
  font-size: x-small;
}
.Toolbar {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: "icon title social";
    grid-template-columns: 80px 1fr 80px;
    align-items: center;
}

.Toolbar button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.Toolbar button:focus {
    outline: none;
}

.Toolbar button:hover {
    color: var(--highlight-color);
    cursor: pointer;
}

.Toolbar-open{
    color: var(--main-bg-color);
}

.Toolbar-menu {
    z-index: 4;
    grid-area: icon;
    display: inline-block;
    vertical-align: center;
    text-align: left;
    margin-left: 16px;
}

.Toolbar-title {
    grid-area: title;
    justify-self: center;
}

.Toolbar-title img {
    height: 30px;
    width: auto;
    fill: var(--secondary-bg-color);
}

.Toolbar-social {
    grid-area: social;
}

.Toolbar-social i {
    color:var(--secondary-bg-color);
    margin: 0 8px;
    font-size: x-large;
}


.Menu {
    display: grid;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--secondary-bg-color);
    width: 0;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    grid-template-areas:
    "."
    "links"
    "signature";
    grid-template-rows: 50px 1fr 50px;
    overflow: hidden;
}

.Menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 20px;
}

.Menu li {
    margin: 0px 0 50px 0;
}

.Menu-link-container {
    width: 200px;
    grid-area: links;
    align-self: center;
    justify-self: center;
}

.Menu-footer {
    grid-area: signature;
    color: var(--main-bg-color);
    font-size: xx-small;
    justify-self: center;
    align-self: end;
    margin-bottom: 16px;
}

.Menu-footer a {
    color: var(--main-bg-color);
    text-decoration: none;
}

.Menu-open {
    width: 200px;
}

.Menu-link {
    font-size: xx-large;
    color: var(--main-bg-color);
    text-decoration: none;
}

.Menu-link:hover {
    color: var(--highlight-color);
}

.Menu-selected {
    text-decoration: underline;
}
.AlbumGrid {
    display: grid;
    grid-gap: 2px;
    margin: 20px auto;
    justify-content: center;
    align-content: center;
    padding: auto;
    overflow:visible;
}


@media screen and (min-width: 320px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 155px);
        grid-template-rows: repeat(auto-fill, 155px);
    }
}

@media screen and (min-width: 374px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 180px);
        grid-template-rows: repeat(auto-fill, 180px);
    }
}

@media screen and (min-width: 411px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 200px);
        grid-template-rows: repeat(auto-fill, 200px);
    }
}

@media screen and (min-width: 735px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 240px);
        grid-template-rows: repeat(auto-fill, 240px);
    }
}

@media screen and (min-width: 900px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 350px);
        grid-template-rows: repeat(auto-fill, 350px);
    }
}
.Album {
    position: relative;
    margin: 0;
    padding: 0;
}

.Album img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-transition: -webkit-filter 0.5s;
    transition: -webkit-filter 0.5s;
    transition: filter 0.5s;
    transition: filter 0.5s, -webkit-filter 0.5s;
    -webkit-filter: blur(0px);
            filter: blur(0px);
}

.Album img:hover {
    -webkit-filter: blur(5px);
            filter: blur(5px);
}

.Album-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    background-color: var(--secondary-bg-color);
    color: var(--main-bg-color);
    text-align: center;
}

.Album-overlay h4{
    margin-top: 75px;
}

.Album:hover .Album-overlay {
    opacity: 0.9;
}
.Release {
    display: grid;
    grid-gap: 8px;
    grid-template-areas:
    "title player"
    "info player";
    grid-template-rows: 1fr 5fr;
    grid-template-columns: 1fr 1fr;
    padding: 16px 20px;
}

@media screen and (max-width: 730px) {
    .Release {
        grid-template-areas:
                "title"
                "player"
                "info";
        grid-template-rows: 0.5fr 4fr 5fr;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 320px) {
    .Release {
        padding: 16px 5px;
    }
}

.Release-title {
    grid-area: title;
    align-self: start;
    justify-self: start;
}

.Release-title a {
    text-decoration: none;
    color: var(--highlight-inverse-color);
}

.Release-title a:hover {
    text-decoration: underline;
}

.Release-info {
    grid-area: info;
    align-self: start;
    justify-self: start;
    white-space: pre-line;
}

.Release-player {
    grid-area: player;
    align-self: start;
    justify-self: center;
}
.Player {

}

.Player iframe {
    border: 0;
    width: 400px;
    height: 700px;
}

@media screen and (max-width: 730px) {
    .Player iframe {
        width: 300px;
    }
}
.ArtistGrid {
    height: 100%;
    position: relative;
}

.ArtistGrid-columns {
    grid-area: cols;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(190px, auto);
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
}

.ArtistGrid-arrow {
    position: absolute;
    grid-area: arrow;
    align-self: center;
    font-size: xx-large;
    z-index: 2;
    right: 0;
    bottom: 0;
    color: var(--main-bg-color);
    vertical-align: middle;
    margin-bottom: 48px;
}

.ArtistGrid-arrow i {
    display: inline;
    vertical-align: middle;
}

.ArtistGrid-arrow i {
    -webkit-animation: 0.5s infinite alternate arrowmovement;
            animation: 0.5s infinite alternate arrowmovement;
    font-size: xx-large;
}

@-webkit-keyframes arrowmovement {
    from {
        margin-right: 0px;
    }
    to {
        margin-right: 4px;
    }
}

@keyframes arrowmovement {
    from {
        margin-right: 0px;
    }
    to {
        margin-right: 4px;
    }
}
.Artist {
    position: relative;
    width: 200px;
    -webkit-transition: width 0.25s;
    transition: width 0.25s;
    z-index: 0;
}

.Artist:hover {
    width: 355px;
    z-index: 1;
}

@media screen and (max-width: 720px) {
    .Artist:hover {
        width: 200px;
    }
}

.Artist img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.Artist-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-bg-color);
    opacity: 0.6;
    color: var(--main-bg-color);
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    -webkit-text-orientation: upright;
            text-orientation: upright;
    padding-top: 8px;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.Artist-overlay:hover {
    opacity: 0.4;
}
.Bio {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.Bio-background{
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-filter: blur(5px);
            filter: blur(5px);
}

.Bio-overlay {
    position: absolute;
    padding: 100px 24px 24px 24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--secondary-bg-color);
    opacity: 0.9;
    color: var(--main-bg-color);
    text-align:center;
    overflow-y: scroll;
}

.Bio-overlay i {
    font-size: xx-large;
    margin: 0 8px;
    color: var(--main-bg-color);
}

.Bio-overlay iframe {
    border:none;
    overflow:hidden;
}

.Bio-overlay-section {
    margin: 16px 0;
}
.Contact {
    margin: 100px 24px;
    text-align:center;
}
