@import url('https://fonts.googleapis.com/css?family=Roboto:300,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css');

:root {
  --main-bg-color: #dddddd;
  --secondary-bg-color: #222222;
  --highlight-color: khaki;
  --highlight-inverse-color: #9d91c3;
}

.App {
  background-color: var(--main-bg-color);
  color: var(--secondary-bg-color);
  font-family: 'Roboto', sans-serif;
  display: grid;
  height: 100vh;
  grid-template-rows: 50px 1fr 40px;
  grid-template-areas:
  "head head head head"
  "main main main main"
  "foot foot foot foot";
}

.App-header {
  grid-area: head;
}

.App-content {
  grid-area: main;
  overflow: scroll;
}

.App-footer {
  grid-area: foot;
  justify-self: center;
  align-self: center;
}

.signature {
  align-self: center;
  justify-self: end;
  font-size: x-small;
}