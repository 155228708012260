.ArtistGrid {
    height: 100%;
    position: relative;
}

.ArtistGrid-columns {
    grid-area: cols;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(190px, auto);
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
}

.ArtistGrid-arrow {
    position: absolute;
    grid-area: arrow;
    align-self: center;
    font-size: xx-large;
    z-index: 2;
    right: 0;
    bottom: 0;
    color: var(--main-bg-color);
    vertical-align: middle;
    margin-bottom: 48px;
}

.ArtistGrid-arrow i {
    display: inline;
    vertical-align: middle;
}

.ArtistGrid-arrow i {
    animation: 0.5s infinite alternate arrowmovement;
    font-size: xx-large;
}

@keyframes arrowmovement {
    from {
        margin-right: 0px;
    }
    to {
        margin-right: 4px;
    }
}