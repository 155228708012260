.Toolbar {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-areas: "icon title social";
    grid-template-columns: 80px 1fr 80px;
    align-items: center;
}

.Toolbar button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.Toolbar button:focus {
    outline: none;
}

.Toolbar button:hover {
    color: var(--highlight-color);
    cursor: pointer;
}

.Toolbar-open{
    color: var(--main-bg-color);
}

.Toolbar-menu {
    z-index: 4;
    grid-area: icon;
    display: inline-block;
    vertical-align: center;
    text-align: left;
    margin-left: 16px;
}

.Toolbar-title {
    grid-area: title;
    justify-self: center;
}

.Toolbar-title img {
    height: 30px;
    width: auto;
    fill: var(--secondary-bg-color);
}

.Toolbar-social {
    grid-area: social;
}

.Toolbar-social i {
    color:var(--secondary-bg-color);
    margin: 0 8px;
    font-size: x-large;
}

