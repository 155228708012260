.AlbumGrid {
    display: grid;
    grid-gap: 2px;
    margin: 20px auto;
    justify-content: center;
    align-content: center;
    padding: auto;
    overflow:visible;
}


@media screen and (min-width: 320px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 155px);
        grid-template-rows: repeat(auto-fill, 155px);
    }
}

@media screen and (min-width: 374px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 180px);
        grid-template-rows: repeat(auto-fill, 180px);
    }
}

@media screen and (min-width: 411px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 200px);
        grid-template-rows: repeat(auto-fill, 200px);
    }
}

@media screen and (min-width: 735px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 240px);
        grid-template-rows: repeat(auto-fill, 240px);
    }
}

@media screen and (min-width: 900px) {
    .AlbumGrid {
        grid-template-columns: repeat(auto-fill, 350px);
        grid-template-rows: repeat(auto-fill, 350px);
    }
}